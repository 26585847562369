/* Main Container */
.login-container {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Logo */
.login-logo {
  width: 130px;
}

.login-logo img {
  width: 100%;
}

/* Form */
.login form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

/* Input */
.login form input {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.6px;
  color: #555555;
  width: 400px;
  margin: 20px 0;
  outline: none;
  padding: 15px 3px;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  transition: all 0.2s linear;
}

.login form input:focus {
  border-bottom: 2px solid var(--primary);
}

.login form input::placeholder {
  font-family: "Poppins", sans-serif;
  padding-left: 12px;
  color: #9c9fa9;
  font-size: 18px;
  font-weight: 400;
}

/* Button */
.login form button:not(.close) {
  padding: 12px 0;
  margin-top: 20px;
  width: 400px;
  outline: none;
  border: none;
  border-radius: 25px;
  color: white;
  background: var(--gradient);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 10px 30px 0px var(--shadow);
  transition: all 0.3s linear;
  background-position: left;
  background-size: 150%;
}

.login form button:not(.close):hover {
  cursor: pointer;
  background-position: right;
}

.login form button:disabled {
  background-position: left !important;
  opacity: 0.5;
  transition: all 0.3s;
}

.signup-btn {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #818ea3;
  margin-top: 20px;
}

/* Media Query */
@media only screen and (max-width: 775px) {
  .login-container {
    min-height: 100vh;
  }
  .login {
    transform: translateY(-10vh);
    width: 100%;
  }
  .login form input,
  .login form button:not(.close) {
    width: 80%;
  }
}
